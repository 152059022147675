/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(_components.strong, null, "no-cloning theorem"), " states that it is not possible to make a perfect copy of an arbitrary, unknown quantum state."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Suppose we have a qubit in some state $|\\psi\\rangle$, and we have another qubit in some standard pure state $|s\\rangle$. ‘Copying’ the state $|\\psi\\rangle$ means, essentially, ‘overwriting’ the state $|s\\rangle$ with $|\\psi\\rangle$, so that the output is two qubits set to $|\\psi\\rangle$, as opposed to just one qubit set to that at the input."), "\n", React.createElement(_components.p, null, "In classical computing, copying the state of one bit to another is a ubiquitous operation. Analogously in quantum computing, if we know that the input state we want to copy is guaranteed to be one of only two orthogonal states (eg $|0\\rangle$ and $|1\\rangle$) then a simple circuit will accomplish the cloning task. However if the input state we wish to copy can be any single-qubit state $|\\psi\\rangle = \\alpha|0\\rangle + \\beta|1\\rangle$ then there is no quantum circuit that will accomplish the task."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
